//
//
//
//
//
//
//
//
//
//

export default {
  props: ['img'],
  data() {
    return {
      src: this.img.src,
      srcset: this.img.srcset,
    };
  },
  created() {
    if (process.server) {
      this.$vnode.data.attrs['src'] = this.img.placeholder;
    }
  },
  mounted() {
    this.$vnode.elm.setAttribute('src', this.img.placeholder);
  },
};
